import {createApp} from 'vue'
import router from "./router";
import store from "./store";
import PrimeVue from "primevue/config";
import App from "./App";
import ToastService from 'primevue/toastservice';

const app = createApp(App);

// Use ToastService

// Bibliotheken benutzen
app.use(router).use(PrimeVue, {
        locale: {
            startsWith: 'Starts with',
            contains: 'Contains',
            notContains: 'Not contains',
            endsWith: 'Ends with',
            equals: 'Equals',
            notEquals: 'Not equals',
            noFilter: 'No Filter',
            lt: 'Less than',
            lte: 'Less than or equal to',
            gt: 'Greater than',
            gte: 'Greater than or equal to',
            dateIs: 'Date is',
            dateIsNot: 'Date is not',
            dateBefore: 'Date is before',
            dateAfter: 'Date is after',
            clear: 'Clear',
            apply: 'Apply',
            matchAll: 'Match All',
            matchAny: 'Match Any',
            addRule: 'Add Rule',
            removeRule: 'Remove Rule',
            accept: 'Ja',
            reject: 'Nein',
            choose: 'Auswählen',
            upload: 'Hochladen',
            cancel: 'Abbrechen',
            dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
            monthNames: ["January","February","March","April","May","June","July","August","September","October","November","December"],
            monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            today: 'Today',
            weekHeader: 'Wk',
            firstDayOfWeek: 0,
            dateFormat: 'mm/dd/yy',
            weak: 'Weak',
            medium: 'Medium',
            strong: 'Strong',
            passwordPrompt: 'Enter a password',
            emptyFilterMessage: 'No results found',
            emptyMessage: 'No available options'
        }
    }
).use(store).use(ToastService)

// CSS
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/saga-blue/theme.css'

// Import PrimeVue components
import Card from "primevue/card";
import Menubar from "primevue/menubar";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import MegaMenu from "primevue/megamenu";
import Toast from 'primevue/toast';
import Message from "primevue/message";
import Image from "primevue/image";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Dialog from "primevue/dialog";
import Checkbox from "primevue/checkbox";
import Menu from "primevue/menu";

// Use PrimeVue components
app.component('Card', Card)
app.component('Menubar', Menubar)
app.component('FileUpload', FileUpload)
app.component('InputText', InputText)
app.component('Button', Button)
app.component('Avatar', Avatar)
app.component('MegaMenu', MegaMenu)
app.component('Toast', Toast)
app.component('Message', Message)
app.component('Image', Image)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Dialog', Dialog)
app.component('Checkbox', Checkbox)
app.component('Menu', Menu)

app.mount('#app')
