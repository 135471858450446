<template>
  <div :id="componentId">
    <div class="p-grid body-grid">
      <div class="p-col-4 ">
        sasa
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userSettings",
  data() {
    return {
      componentId: 'globalLogin',
    }
  },
  computed: {
    theTitle() {
      return this.$route.meta.headline
    }
  }
}
</script>

<style scoped>

</style>