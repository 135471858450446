import apiCallService from "@/_services/apiCallService";

const state = {
    hasAuth: false,
    userData: {},
    userToken: null
}

const mutations = {
    SET_USER_AUTH(state, payload) {
        state.hasAuth = payload;
    },
    CLEAR_USER_AUTH(state, payload) {
        state.hasAuth = payload;
    },
    GET_USER_DATA(state, payload) {
        state.userData = payload;
    },
    CLEAR_USER_DATA(state, payload) {
        state.userData = payload;
    },
    GET_USER_TOKEN(state, payload) {
        state.userToken = payload;
    },
    CLEAR_USER_TOKEN(state, payload) {
        state.userToken = payload;
    }
}

const actions = {
    getUserAuth({commit}, apiParams) {
        return apiCallService.callApiService('user/auth/', 'get', apiParams )
            .then((response) => {
                commit('SET_USER_AUTH', true);
                commit('GET_USER_TOKEN', response.token[0]);
                commit('GET_USER_DATA', response.data[0]);
            })
    },
    clearUserAuth({commit}) {
        commit('CLEAR_USER_AUTH', false);
        commit('CLEAR_USER_TOKEN', null);
        commit('CLEAR_USER_DATA', {});
    },
    setUserToken({commit}, payload) {
        commit('GET_USER_TOKEN', payload);
    }
}

const getters = {
    getUserAuth: state => state.hasAuth,
    getUserToken: state => state.userToken,
    getUserData: state => state.userData
}
const authModule = {
    state,
    actions,
    mutations,
    getters
}

export default authModule;