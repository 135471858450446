<template>
  <Dialog :closable="false" position="top" header="Ordner erstellen" :modal="true" :visible="showFolderDialogBoolean" style="width: 500px;">
    <br><br>
    <InputText id="foldername" type="text" v-model.trim="folderName" style="width: 100%;"/>
    <br><br>
    <div class="p-field-checkbox" style="width: 100%;">
      <Checkbox id="securedFolder" v-model="securedFolder" :value="1" />
      <label for="securedFolder">Gesicherter Ordner</label>
    </div>
    <br><br>
    <div>
      <Button style="float: left;" @click="closeDialog" label="Abbrechen" /><Button style="float: right;" @click="createFolder" label="Anlegen" />
    </div>
  </Dialog>
</template>

<script>
export default {
  name: "globalCreateFolderDialog",
  emits: ['create-folder', 'close-dialog'],
  props: {
    showFolderDialogBoolean: Boolean
  },
  data() {
    return {
      folderName: '',
      securedFolder: 0
    }
  },
  methods: {
    createFolder() {
      let self = this;
      if(this.folderName !== '') {
        let parent = 0;
        if(self.thisParent[0]) {
          parent = self.thisParent[0].id
        }
        this.$emit('create-folder', {folderName: this.folderName, parent: parent, securedFolder: this.securedFolder})
      }
      else {
        this.$toast.add({severity:'error', summary: 'Fehler', detail: 'Ordnername leer!', life: 3000});
      }
      this.folderName = '';
    },
    closeDialog() {
      this.$emit('close-dialog')
    }
  },
  computed: {
    curentFolders() {
      return this.$store.getters.showCurentFolders
    },
    thisParent() {
      return this.$store.getters.showCurentFold;
    }
  }
}
</script>

<style scoped>

</style>