<template>
  <div :id="componentId">
    <div class="p-grid header-grid">
      <div class="p-col-12 ">
        <h1>{{theTitle}}</h1>
        <span class="header-button">
          <Button v-if="this.$store.getters.setDeleteMultiple" type="button" @click="deleteMultipleFileDialog">
              <span class="button-text">Löschen</span>
          </Button>
          <Button style="margin-left: 10px" v-if="this.$route.name === 'Dateien'" type="button" @click="showFolderDialog">
              <i class="pi pi-plus"></i>
              <span class="button-text" style="margin-left: 10px;">Ordner</span>
          </Button>
          <Button style="margin-left: 10px" v-if="checkNestedFolder" type="button" @click="folderUp">
              <i class="pi pi-arrow-left button-icon"></i>
              <span class="button-text" style="margin-left: 10px;">Zurück</span>
          </Button>
        </span>
      </div>
    </div>
    <div>
      <Accordion @dragover="tabActiveOnMouse()" v-if="$route.name !== 'Login'" class="download-accordion" :active-index="tabActive" :d_activeIndex="tabActive">
        <AccordionTab header="Upload" >
          <file-upload-box @closeUploadBox="closeUploadBox"></file-upload-box>
        </AccordionTab>
      </Accordion>
    </div>
    <div class="p-grid body-grid breadcrumb-container">
      <div class="p-sm-11">
        <i class="pi pi-home"></i> <router-link to="/dateien">Dateien</router-link>
        <span v-if="thisFolders.length">
          <span v-for="(folder, index) in thisFolders" :key="index"> <i class="pi pi-chevron-right"></i> <router-link v-if="(index + 1) !== thisFolders.length" :to="generateBreadcrumbLink(folder)">{{ folder }}</router-link><span class="last-breadcrumb" v-else>{{ folder }}</span></span>
        </span>
      </div>
      <div class="p-sm-1" style="text-align: right;">
        <i class="pi pi-file"></i>{{curentFiles.length}}
      </div>
    </div>
    <div class="p-grid body-grid">
      <div v-for="folder in curentFolders" :key="folder.id" class="p-col-4 p-md-2 p-lg-2 p-xl-1">
        <div class="xl-folder-icon" @dblclick="openFolder(folder)">
          <img alt="foldericon" src="@/assets/file-icons/folder.png">
          <div class="xl-folder-name">{{folder.name}}</div>
        </div>
      </div>
      <div v-for="file in curentFiles" :key="file.id" class="p-col-4 p-md-2 p-lg-2 p-xl-1">
        <show-single-file @deleteFile="deleteFileDialog" :file="file" @markFile="markFile"></show-single-file>
      </div>
    </div>
    <global-yes-no-dialog @clickYes="deleteSingleFile" @clickNo="deleteFileDialog" :elementId="parseInt(deleteSingleId)" :displayDialog="displayDialog" :dialogHeader="dialogHeader">
      Möchtest Du <b>{{deleteSingleName}}</b> wirklich löschen?
    </global-yes-no-dialog>
    <global-yes-no-dialog @clickYes="deleteMultipleFiles" @clickNo="deleteMultipleFileDialog" :displayDialog="displayMultipleDialog" :dialogHeader="'Dateien löschen'">
      Möchtest Du <b>{{showFilesCount}}</b> Dateien wirklich löschen?
    </global-yes-no-dialog>
    <global-create-folder-dialog :showFolderDialogBoolean="showFolderDialogBoolean" @createFolder="createNewFolder" @closeDialog="closeThisDialog"></global-create-folder-dialog>
  </div>
</template>

<script>
import fileUploadBox from "@/components/ui-blocks/fileUploadBox";
import showSingleFile from "@/components/global-components/micro-components/showSingleFile";
import globalYesNoDialog from "@/components/global-components/micro-components/globalYesNoDialog";
import globalCreateFolderDialog from "@/components/global-components/micro-components/globalCreateFolderDialog";

export default {
  name: "userOverview",
  components: {
    fileUploadBox,
    showSingleFile,
    globalYesNoDialog,
    globalCreateFolderDialog
  },
  data() {
    return {
      componentId: 'userOverview',
      thisUser: this.$store.getters.getUserData,
      thisFolders: this.$route.params.folder,
      imageUrl: process.env.VUE_APP_FILE_URL,
      tabActive: 1,
      displayDialog: false,
      displayMultipleDialog: false,
      dialogHeader: null,
      deleteSingleName: null,
      deleteSingleId: null,
      deleteMultiIds: {},
      showFolderDialogBoolean: false,
      parentCurent: {}
    }
  },
  methods: {
    async createNewFolder(folderObject) {
      this.getCurentAndParent();
      this.showFolderDialogBoolean = false;
      await this.$store.dispatch('createNewFolder', {name: folderObject.folderName, parent: this.parentCurent.curent, user: this.thisUser.id, secured: folderObject.securedFolder}).then(
          this.$store.dispatch('getAllCurentFolders', {id: this.parentCurent.curent, parent: this.parentCurent.parent, user: this.thisUser.id})
      ).then(
          this.setFolderStructure
      ).then(
          this.getCurentFolders
      )
    },
    closeThisDialog() {
      this.showFolderDialogBoolean = false;
    },
    async deleteMultipleFiles() {
      this.displayMultipleDialog = false;
      await Object.keys(this.$store.getters.setDeleteMultiple).forEach(index => {
        this.$store.dispatch('deleteSingleFile', {fileId: index, user: this.thisUser.id})
      });
      this.$store.dispatch('multipleDelete', null)
      await this.getThisFolder()
    },
    deleteMultipleFileDialog() {
      this.displayMultipleDialog = !this.displayMultipleDialog;
    },
    markFile(markObject) {
      if (markObject.isChecked) {
        Object.assign(this.deleteMultiIds, {[markObject.fileId]: markObject.fileId})
      } else {
        delete this.deleteMultiIds[markObject.fileId]
      }

      if (Object.keys(this.deleteMultiIds).length === 0) {
        this.$store.dispatch('multipleDelete', null)
      } else {
        this.$store.dispatch('multipleDelete', this.deleteMultiIds)
      }
    },
    getCurentAndParent() {
      let curentId = null;
      let parentId = null;
      if (this.$route.params.folder.length) {
        if ((this.$route.params.folder.length - 1) > 0) {
          curentId = parseInt(this.getFolderStructure.find(element => element.name === this.$route.params.folder[this.$route.params.folder.length - 1] && parseInt(element.parent) !== 0).id)
          // curentId = parseInt(this.getFolderStructure.find(element => element.name === this.$route.params.folder[this.$route.params.folder.length - 1] && parseInt(element.parent) === this.getFolderStructure.find(parent => parent.name === this.$route.params.folder[this.$route.params.folder.length - 2]).id).id)

          parentId = parseInt(this.getFolderStructure.find(element => parseInt(element.id) === curentId).parent)
        } else {
          parentId = 0;
          curentId = parseInt(this.getFolderStructure.find(element => element.name === this.$route.params.folder[this.$route.params.folder.length - 1] && parseInt(element.parent) === parentId).id)
        }
      } else {
        parentId = 0;
      }
      this.parentCurent = {parent: parentId, curent: curentId}
    },
    async getCurentFolders() {
      this.getCurentAndParent();
      await this.$store.dispatch('getAllCurentFolders', {id: this.parentCurent.curent, parent: this.parentCurent.parent, user: this.thisUser.id})
      await this.$store.dispatch('getAllFiles', {id: this.parentCurent.curent, parent: this.parentCurent.parent, user: this.thisUser.id})
    },
    async setFolderStructure() {
      await this.$store.dispatch('setFolderStructure', {user: this.thisUser.id})
    },
    openFolder(folder) {
      if (this.$route.fullPath.slice(-1) === '/') {
        this.$router.push(this.$route.fullPath + folder.name)
      }
      else {
        this.$router.push(this.$route.fullPath + '/' + folder.name)
      }
    },
    tabActiveOnMouse() {
      this.tabActive = 0
    },
    openFile() {

    },
    async deleteSingleFile(fileId) {
      let file = this.curentFiles.find(f => f.id === fileId.toString())
      this.displayDialog = false;
      this.dialogHeader = null;
      this.deleteSingleName = null;
      this.deleteSingleId = null;
      await this.$store.dispatch('deleteSingleFile', {fileId: fileId, user: this.thisUser.id})
          .then(
              this.$toast.add({severity:'success', detail: file.name + '.' + file.ext + ' gelöscht.', life: 3000})

          ).then(
              this.getThisFolder()
          )
    },
    deleteFileDialog(fileInfo) {
      if(this.displayDialog) {
        this.displayDialog = false;
        this.dialogHeader = null;
        this.deleteSingleName = null;
        this.deleteSingleId = null;
      } else {
        let file = this.curentFiles.find(f => f.id === fileInfo.fileId)
        this.displayDialog = true;
        this.deleteSingleId = fileInfo.fileId;
        this.dialogHeader = 'Datei löschen';
        this.deleteSingleName = file.name + '.' + file.ext;
      }
    },
    toggleItem() {
      this.tabActive = 1
    },
    generateBreadcrumbLink(folder) {
      let thisPath = '/dateien/'
      for (let fold in this.thisFolders) {
        thisPath = thisPath + this.thisFolders[fold] + '/'
        if(this.thisFolders[fold] === folder) {
          return thisPath;
        }
      }
    },
    closeUploadBox() {
      this.toggleItem()
      this.getCurentFolders();
    },
    shortenFilename(filename) {
      if(filename.length > 10) {
        return filename.substring(0, 9) + '...'
      }
      else {
        return filename
      }
    },
    async getThisFolder() {
      this.getCurentAndParent();
      if(this.$route.params.folder.length === 1) {
        console.log(this.parentCurent)
        await this.$store.dispatch('getSingleCurentFolder', {id: this.parentCurent.curent, parent: this.parentCurent.parent, user: this.thisUser.id})
            .then((response) => {
                  this.getCurentFolders(response)
                }
            )
      }
      else if (this.$route.params.folder.length > 1) {
        console.log(this.parentCurent)
        await this.$store.dispatch('getSingleCurentFolder', {id: this.parentCurent.curent, parent: this.parentCurent.parent, user: this.thisUser.id})
            .then((response) => {
                  this.getCurentFolders(response)
                }
            )
      }
      else {
        await this.$store.dispatch('clearSingleCurentFolder').then(
            this.getCurentFolders()
        )
      }
    },
    showFolderDialog() {
      this.showFolderDialogBoolean = true;
    },
    folderUp() {
      this.$router.go(-1);
    }
  },
  created() {
    this.setFolderStructure();
    this.getThisFolder();
  },
  watch:{
    async $route (to, from){
      if (to !== from && to.name === 'Dateien') {
        await this.$store.dispatch('clearAllCurentFolder');
        await this.$store.dispatch('clearAllFiles');
        this.getCurentAndParent();
        this.getThisFolder();
        this.thisFolders = this.$route.params.folder;
      }
    }
  },
  computed: {
    theTitle() {
      return this.$route.meta.headline;
    },
    curentFolders() {
      return this.$store.getters.showCurentFolders;
    },
    curentFold() {
      return this.$store.getters.showCurentFold;
    },
    curentFiles() {
      return this.$store.getters.showAllFiles;
    },
    checkNestedFolder() {
      return this.$route.params.folder;
    },
    getFolderStructure() {
      return this.$store.getters.getFolderStructure;
    },
    showFilesCount() {
      if (this.$store.getters.setDeleteMultiple) {
        return Object.keys(this.$store.getters.setDeleteMultiple).length;
      } else {
        return null;
      }
    }
  }
}
</script>

<style scoped>
.xl-folder-icon:hover {
  background: #e5e5e5;
  border: 1px solid #cbcbcb;
}
.last-breadcrumb {
  font-size: 15px;
  font-weight: bold;
}
</style>
