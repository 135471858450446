<template>
  <div :id="componentId">
    <div v-if="$route.params.downloadId && isLoaded && spottedFile && !downloadClicked" class="center">
      <div class="download-wrapper" @click="downloadClick(dataPath + spottedFile.name + '.' + spottedFile.ext, spottedFile.name+ '.' + spottedFile.ext)">
        <img class="download-icon" style="padding: 25px; max-width: 200px; height: auto" :src="imgUrl(spottedFile.ext)" :alt="spottedFile.name">
        <div class="xl-folder-name">{{spottedFile.name}}.{{spottedFile.ext}}</div>
        <i class="pi download-symbol pi-download"></i>
      </div>
    </div>
    <div v-else-if="$route.params.downloadId && !isLoaded && !spottedFile && !downloadClicked" class="center">
      <i class="pi pi-spin pi-spinner" style="fontSize: 2rem"></i>
      <br><br>
      <div>Wird geladen ...</div>
    </div>
    <div v-else-if="downloadClicked" class="center">
      <i class="pi pi-spin pi-spinner" style="fontSize: 2rem"></i>
      <br><br>
      <div>Download beginnt ...</div>
    </div>
    <div v-else-if="noSuchFile" class="center">
      <div>Diese Datei existiert nicht.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "publicDownload",
  data() {
    return {
      componentId: 'publicDownload',
      dataPath: process.env.VUE_APP_FILE_URL,
      isLoaded: false,
      spottedFile: null,
      downloadClicked: false,
      noSuchFile: false
    }
  },
  methods: {
    imgUrl(img) {
      return require('@/assets/file-icons/' + img + '.png')
    },
    shortenFilename(filename) {
      if(filename.length > 20) {
        return filename.substring(0, 19) + '...'
      }
      else {
        return filename
      }
    },
    async downloadClick(fileUrl, fileName) {
      this.downloadClicked = true;
      let FileSaver = require('file-saver');
      await FileSaver.saveAs(fileUrl, fileName);
    },
    async getDownloadFile() {
      await this.$store.dispatch('getDownloadFile', {dlToken: this.$route.params.downloadId}).finally(this.setDownloadFile())
    },
    setDownloadFile() {
      try {
        if(this.$store.getters.showDownloadFile.data) {
          this.spottedFile = this.$store.getters.showDownloadFile.data[0];
          this.isLoaded = true;
        } else {
          setTimeout(() => this.setDownloadFile(), 1000);
        }
      } catch (e) {
        this.noFileFound(e)
      }
    },
    noFileFound(e) {
      console.log(e)
      this.noSuchFile = true;
    }
  },
  created() {
    this.$store.dispatch('clearDownloadFile');
    if(this.$route.params.downloadId) {
      this.getDownloadFile()
    }
  },
  watch:{
    async $route (to, from){
      if (to !== from && to.name === 'Dateien') {
        await this.getDownloadFile()
      }
    }
  }
}
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
  padding: 5% 10%;
  text-align: center;
}
.download-symbol {
  position: absolute;
  margin: auto;
  left: 25%;
  top: 40%;
  width: 50%;
  font-size: 3rem;
  color: #6900b6;
  font-weight: bold;
  display: none;
}
.download-wrapper {
  position: relative;
}
.download-wrapper:hover img {
  opacity: 30%;
}
.download-wrapper:hover .download-symbol {
  display: block;
}
.download-wrapper:hover {
  cursor: pointer;
}
</style>