<template>
  <div>
    <div v-if="file.ext === 'jpg' || file.ext === 'jpeg' || file.ext === 'png'" class="file-wrapper">
      <div class="xl-folder-icon" :class="{ isChecked: fileIsChecked }" @dblclick="openImage(file)">
        <input @change="markFile" class="download-check" type="checkbox" :name="'check-' + file.id" :value="true" v-model="fileIsChecked">
        <div class="download-menu" @click="toggle"><i class="pi pi-bars"></i></div>
        <Menu :id="'menu-' + file.id" :ref="file.id" :model="fileMenuItems" :popup="true" />
        <img style="padding: 8px;" :src="dataPath + file.name + '_thumb.jpg'" :alt="file.name">
        <div class="xl-folder-name">{{shortenFilename(file.name)}}.{{file.ext}}</div>
      </div>
    </div>
    <div v-else-if="file.ext === 'mp4' || file.ext === '3gp' || file.ext === 'mpg'" class="file-wrapper">
      <div class="xl-folder-icon" :class="{ isChecked: fileIsChecked }" @dblclick="openImage(file)">
        <input @change="markFile" class="download-check" type="checkbox" :name="'check-' + file.id" :value="true" v-model="fileIsChecked">
        <div class="download-menu" @click="toggle"><i class="pi pi-bars"></i></div>
        <Menu :id="'menu-' + file.id" :ref="file.id" :model="fileMenuItems" :popup="true" />
        <img class="download-icon" style="padding: 25px" :src="imgUrl('mp4')" :alt="file.name">
        <div class="xl-folder-name">{{shortenFilename(file.name)}}.{{file.ext}}</div>
      </div>
    </div>
    <div v-else-if="file.ext === 'txt'" class="file-wrapper">
      <div class="xl-folder-icon" :class="{ isChecked: fileIsChecked }" @dblclick="openTxt(file)">
        <input @change="markFile" class="download-check" type="checkbox" :name="'check-' + file.id" :value="true" v-model="fileIsChecked">
        <div class="download-menu" @click="toggle"><i class="pi pi-bars"></i></div>
        <Menu :id="'menu-' + file.id" :ref="file.id" :model="fileMenuItems" :popup="true" />
        <img class="download-icon" style="padding: 25px" :src="imgUrl('txt')" :alt="file.name">
        <div class="xl-folder-name">{{shortenFilename(file.name)}}.{{file.ext}}</div>
      </div>
    </div>
    <div v-else class="file-wrapper">
      <div class="xl-folder-icon" :class="{ isChecked: fileIsChecked }">
        <input @change="markFile" class="download-check" type="checkbox" :name="'check-' + file.id" :value="true" v-model="fileIsChecked" >
        <div class="download-menu" @click="toggle"><i class="pi pi-bars"></i></div>
        <Menu :id="'menu-' + file.id" :ref="file.id" :model="fileMenuItems" :popup="true" />
        <img class="download-icon" style="padding: 25px" :src="imgUrl(file.ext)" :alt="file.name">
        <div class="xl-folder-name">{{shortenFilename(file.name)}}.{{file.ext}}</div>
      </div>
    </div>
    <show-single-image v-if="fileToShowOpen" :file="fileToShow" :showImageModal="fileToShowOpen" @closeImage="closeImage" @nextImage="nextImage" @stopDiashow="stopDiashow" @startDiashow="startDiashow" ></show-single-image>
    <show-single-textfile v-if="textfileToShowOpen" :file="fileToShow" :showImageModal="textfileToShowOpen" @closeTextfile="closeTextfile" ></show-single-textfile>
  </div>
</template>

<script>
import showSingleImage from "@/components/global-components/micro-components/showSingleImage";
import showSingleTextfile from "@/components/global-components/micro-components/showSingleTextfile";

export default {
  name: "showSingleFile",
  components: {
    showSingleImage,
    showSingleTextfile
  },
  props: {
    file: Object
  },
  data() {
    return {
      acceptedExt: ['jpg', 'jpeg', 'png', 'mp4'],
      fileToShow: {},
      fileToShowOpen: false,
      textfileToShowOpen: false,
      dataPath: process.env.VUE_APP_FILE_URL,
      fileIsChecked: false,
      fileMenuItems: [
        {
          label: 'Herunterladen',
          icon: 'pi pi-download',
          command: () => {
            this.downloadClick(this.dataPath + this.file.name + '.' + this.file.ext, this.file.name+ '.' + this.file.ext)
          }
        },
        {
          label: 'Öffnen',
          icon: 'pi pi-window-maximize',
          command: () => {
            if(this.file.ext === 'txt') {
              this.openTxt(this.file)
            } else {
              this.openImage(this.file)
            }
          }
        },
        {
          label: 'Teilen',
          icon: 'pi pi-share-alt',
          command: () => {
            this.$emit('openFile', this.file.id)
          }
        },
        {
          label: 'Verschieben',
          icon: 'pi pi-sign-in',
          command: () => {
            this.$emit('openFile', this.file.id)
          }
        },
        {
          label: 'Umbenennen',
          icon: 'pi pi-pencil',
          command: () => {
            this.$emit('openFile', this.file.id)
          }
        },
        {
          label: 'Löschen',
          icon: 'pi pi-trash',
          command: () => {
            this.$emit('deleteFile', {fileId: this.file.id, fileName: this.file.name + '.' + this.file.ext})
          }
        }
      ]
    }
  },
  methods: {
    async checkFileExist(file) {
      await this.$store.dispatch('checkVideoThumb', {name: file.name, ext: file.ext})
      .then(response => {
        return response
      })
    },
    checkThumbExist(file) {
      if(this.checkFileExist(file)) {
        return this.dataPath + file.name + '_vthumb.jpg';
      } else {
        return this.imgUrl(file.ext);
      }
    },
    markFile() {
      this.$emit('markFile', {fileId: this.file.id, isChecked: this.fileIsChecked})
    },
    toggle(event) {
      this.$refs[this.file.id].toggle(event);
    },
    shortenFilename(filename) {
      if(filename.length > 15) {
        return filename.substring(0, 14) + '...'
      }
      else {
        return filename
      }
    },
    downloadClick(fileUrl, fileName) {
      let FileSaver = require('file-saver');
      FileSaver.saveAs(fileUrl, fileName);
    },
    imgUrl(img) {
      return require('@/assets/file-icons/' + img + '.png')
    },
    openImage(file) {
      this.fileToShow = file;
      this.fileToShowOpen = true;
    },
    openTxt(file) {
      this.fileToShow = file;
      this.textfileToShowOpen = true
    },
    closeTextfile() {
      this.textfileToShowOpen = false;
    },
    closeImage() {
      this.fileToShowOpen = false;
    },
    stopDiashow() {
      this.startDiashow(null, true)
    },
    startDiashow(data, stop = false) {
      if(!stop) {
        let self = this;
        this.diashowInterval = setInterval(function (){
          let imageID = self.nextImage(data, true)
          data.image = imageID
        }, 4000)
      } else {
        clearInterval(this.diashowInterval)
      }
    },
    nextImage(data, diashow = false) {
      let calledIndex = 0;
      let maxIndex = parseInt(Object.keys(this.curentFiles).length, 10) - 1;
      Object.keys(this.curentFiles).forEach(index => {
        if (this.curentFiles[index].id === data.image) {
          calledIndex = index;
        }
      });
      if (data.action === 'next') {
        if(diashow) {
          return this.forward(parseInt(calledIndex), maxIndex, true)
        } else {
          this.forward(parseInt(calledIndex), maxIndex)
        }

      }
      else {
        this.backward(parseInt(calledIndex), maxIndex)
      }
    },
    backward(calledIndex, maxIndex) {
      if (calledIndex === 0) {
        calledIndex = maxIndex;
      }
      else {
        calledIndex = calledIndex - 1;
      }
      for (let i = calledIndex; i > -1; i--) {
        if (this.acceptedExt.includes(this.curentFiles[i].ext)) {
          this.fileToShow = this.curentFiles[i];
          break;
        }
        if (i === 0) {
          i = maxIndex;
        }
      }
    },
    forward(calledIndex, maxIndex, diashow = false) {
      if (calledIndex === maxIndex) {
        calledIndex = 0;
      }
      else {
        calledIndex = calledIndex + 1;
      }
      for (let i = calledIndex; i < maxIndex + 1; i++) {
        if (this.acceptedExt.includes(this.curentFiles[i].ext)) {
          this.fileToShow = this.curentFiles[i];
          if(diashow) {
            return this.fileToShow.id
          }
          break;
        }
        if (i === maxIndex) {
          i = 0;
        }
      }
    }
  },
  computed: {
    curentFiles() {
      return this.$store.getters.showAllFiles
    }
  }
}
</script>

<style scoped>
.file-wrapper {
  position: relative;
}
.download-check {
   position: absolute;
   left: 9px;
   top: 12px;
   color: white;
   border-radius: 4px;
   padding: 2px 4px;
   display: none;
   cursor: pointer;
   z-index: 90;
 }
.download-menu {
  position: absolute;
  right: 9px;
  top: 12px;
  color: #000000;
  border-radius: 4px;
  padding: 2px 4px;
  display: none;
  cursor: pointer;
  z-index: 90;
}
.xl-folder-icon:hover .download-menu, .xl-folder-icon:hover .download-check {
  display: block;
}
.xl-folder-icon {
  border: 1px solid transparent;
  border-top: 3px solid transparent;
}
.xl-folder-icon:hover {
  background: #efefef;
  border: 1px solid #cbcbcb;
  border-top: 3px solid transparent;
}
.xl-folder-icon.isChecked {
  background: #e2e2e2;
  border: 1px solid #cbcbcb;
  border-top: 3px solid #6900b6;
}
.xl-folder-icon.isChecked .download-menu, .xl-folder-icon.isChecked .download-check {
  display: block;
}
.show-image-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
  height: 20px;
}
</style>
