<template>
  <div>
    <FileUpload class="upload-box" name="demo[]" url="" :previewWidth="0" :showCancelButton="false" :showUploadButton="true" @upload="uploadFile" ref="file" :multiple="true" :maxFileSize="1000000000" :auto="false">
      <template #empty>
        <p>Dateien hier einfügen für den Upload.</p>
      </template>
    </FileUpload>
    <Message v-for="msg of messages" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "fileUploadBox",
  data() {
    return {
      demo: [],
      file:'',
      successAlert:false,
      errorAlert:false,
      uploadedImage:'',
      messages: [],
      thisUser: this.$store.getters.getUserData,
      parentCurent: {}
    }
  },
  methods: {
    getCurentAndParent() {
      let curentId = null;
      let parentId = null;
      if (this.$route.params.folder.length) {
        if ((this.$route.params.folder.length - 1) > 0) {
          curentId = parseInt(this.getFolderStructure.find(element => element.name === this.$route.params.folder[this.$route.params.folder.length - 1] && parseInt(element.parent) !== 0).id)
          // curentId = parseInt(this.getFolderStructure.find(element => element.name === this.$route.params.folder[this.$route.params.folder.length - 1] && parseInt(element.parent) === this.getFolderStructure.find(parent => parent.name === this.$route.params.folder[this.$route.params.folder.length - 2]).id).id)

          parentId = parseInt(this.getFolderStructure.find(element => parseInt(element.id) === curentId).parent)
        } else {
          parentId = 0;
          curentId = parseInt(this.getFolderStructure.find(element => element.name === this.$route.params.folder[this.$route.params.folder.length - 1] && parseInt(element.parent) === parentId).id)
        }
      } else {
        parentId = 0;
      }
      this.parentCurent = {parent: parentId, curent: curentId}
    },
    uploadFile(){
      let self = this;
      this.$refs.file.files.forEach(function(item){
        let formData = new FormData();
        formData.append('file', item);
        formData.append('folder', self.parentCurent.curent);
        formData.append('size', item.size);
        formData.append('user', self.thisUser.id);
        axios.post(process.env.VUE_APP_API_URL + 'upload/', formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data; charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*'
              }
            })
            .then(function (response) {
              if(response.data.status === 0){
                self.$toast.add({severity:'error', detail: response.data.file + ' nicht hochgeladen.', life: 3000});
                self.$emit('closeUploadBox')
              }
              else{
                self.$toast.add({severity:'success', detail: response.data.file + ' erfolgreich hochgeladen.', life: 3000})
                self.$emit('closeUploadBox')
              }
            })
            .catch(function (error) {
              self.$toast.add({severity:'error', summary: 'Fehler', detail: error, life: 3000});
              self.$emit('closeUploadBox')
            }
        );
      });
    }
  },
  watch:{
    async $route (to, from){
      if (to !== from && to.name === 'Dateien') {
        this.getCurentAndParent();
      }
    }
  },
  created() {
    this.getCurentAndParent()
  },
  computed: {
    thisParent() {
      return this.$store.getters.showCurentFold;
    },
    getFolderStructure() {
      return this.$store.getters.getFolderStructure;
    }
  }
}
</script>