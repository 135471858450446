import apiCallService from "@/_services/apiCallService";

const state = {
    usedStorage: null
}

const mutations = {
    GET_USED_STORAGE(state, payload) {
        state.usedStorage = payload;
    },
    CLEAR_USED_STORAGE(state, payload) {
        state.usedStorage = payload;
    }
}

const actions = {
    getUserUsedStorage({commit}, apiParams) {
        return apiCallService.callApiService('storage/', 'get', apiParams )
            .then((response) => {
                commit('GET_USED_STORAGE', response);
            })
    },
    clearUserUsedStorage({commit}) {
        commit('CLEAR_USED_STORAGE', null);
    }
}

const getters = {
    getUsedStorage: state => state.usedStorage
}
const storageModule = {
    state,
    actions,
    mutations,
    getters
}

export default storageModule;
