import apiCallService from "@/_services/apiCallService";

const state = {
    downloadFile: {},
    downloadLink: {}
}

const mutations = {
    GET_DOWNLOAD_FILE(state, payload) {
        state.downloadFile = payload;
    },
    GET_DOWNLOAD_LINK(state, payload) {
        state.downloadLink = payload;
    },
    CLEAR_DOWNLOAD_FILE(state, payload) {
        state.downloadFile = payload;
    }
}

const actions = {
    getDownloadFile({commit}, apiParams) {
        return apiCallService.callApiService('downloads/get/', 'get', apiParams )
            .then((response) => {
                commit('GET_DOWNLOAD_FILE', response);
            })
            .then((response) => {
                return response;
            })
    },
    setDownloadFile({commit}, apiParams) {
        return apiCallService.callApiService('downloads/', 'post', apiParams )
            .then((response) => {
                commit('GET_DOWNLOAD_FILE', response);
            })
    },
    clearDownloadFile({commit}) {
        commit('CLEAR_DOWNLOAD_FILE', {});
    }
}

const getters = {
    showDownloadFile: state => state.downloadFile,
    showDownloadLink: state => state.downloadLink
}
const downloadModule = {
    state,
    actions,
    mutations,
    getters
}

export default downloadModule;
