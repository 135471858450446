<template>
  <div :id="componentId">
    <div class="center">
      <div class="not-found-header">#404</div>
      Seite nicht gefunden.
    </div>
  </div>
</template>

<script>
export default {
  name: "publicNotFound",
  data() {
    return {
      componentId: 'publicNotFound'
    }
  }
}
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
  padding: 20% 10%;
  text-align: center;
}
.not-found-header {
  font-size: 36px;
  font-weight: bold;
}
</style>