<template>
  <div>{{this.$store.getters.getUserAuth}}</div>
</template>

<script>
export default {
  name: "globalLogout",
  created() {
    this.logout()
  },
  methods: {
    async logout() {
      await this.$store.dispatch('clearUserAuth').then(
          await this.$store.dispatch('clearSingleCurentFolder').then(
              await this.$store.dispatch('clearUserUsedStorage').then(
                  this.$router.push('/login')
              )
          )
      )
    }
  }
}
</script>