<template>
  <div :id="componentId">
    <div class="p-grid body-grid">
      <div class="p-lg-6 p-lg-offset-3 p-sm-12 p-sm-offset-0">
        <Card>
          <template #content>
            <form action="" @submit.prevent="getUserAuth">
              <div class="p-grid">
                <div class="p-col-12">
                <span class="p-float-label">
                  <InputText id="email" type="email" v-model="inputValue.email" />
                  <label for="email">E-Mail</label>
                </span>
                </div>
                <div class="p-col-12">
                <span class="p-float-label">
                  <InputText id="password" type="password" autocomplete="on" v-model="inputValue.password" />
                  <label for="password">Passwort</label>
                </span>
                </div>
                <div class="p-col-12 p-text-right">
                  <Button label="Einloggen" @click="getUserAuth" />
                </div>
              </div>
            </form>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "globalLogin",
  data() {
    return {
      componentId: 'globalLogin',
      inputValue: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    getUserAuth() {
      this.$store.dispatch('getUserAuth', {email: this.inputValue.email, password: this.inputValue.password})
      setTimeout(this.pushUrl, 500)
    },
    pushUrl() {
      this.$router.push('/dashboard')
    }
  },
  computed: {
    theTitle() {
      return this.$route.meta.headline
    },
    checkUserAuth() {
      return this.$store.getters.getUserAuth
    }
  }
}
</script>

<style scoped>
#globalLogin {
  margin-top: 150px;
}
</style>