import axios from "axios";
import store from "@/store";
import router from "@/router";

async function callApiService (apiServiceUrl, apiCallAction, apiParams) {
    let userToken = ''
    if (store.getters.getUserToken) {
        userToken = store.getters.getUserToken
    }
    Object.assign(apiParams, {token: userToken.token})
    let apiCallHeader = {}
    if (apiCallAction === 'post') {
        apiCallHeader = {
            'Content-Type': 'multipart/form-data; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*'
        }
        let formData = new FormData();
        for (const [key, value] of Object.entries(apiParams)) {
            formData.append(key, value)
        }
        return axios.post((process.env.VUE_APP_API_URL +  apiServiceUrl), formData, {headers: apiCallHeader})
            .then(response => {
                if(process.env.VUE_APP_SHOW_CONSOLE === 'true') {
                    console.log("[API] SUCCESS! URL: " + process.env.VUE_APP_API_URL + apiServiceUrl + ", Action: " + apiCallAction + ", Stat: " + response.status + ", Data: " + JSON.stringify(apiParams));
                }
                return response.data
            }).catch(error => {
                if(process.env.VUE_APP_SHOW_CONSOLE === 'true') {
                    console.log("[API] ERROR! URL: " + process.env.VUE_APP_API_URL + apiServiceUrl + ", Action: " + apiCallAction + ", Stat: " + error.response.status + ", Data: " + JSON.stringify(apiParams));
                }
                checkUnauthorized(error.response.status)
                return {error: {url: apiServiceUrl, status: error.response.status}, data:[]}
            })
    }
    else {
        apiCallHeader = {
            'Content-Type': 'application/json'
        };
        return axios.get((process.env.VUE_APP_API_URL +  apiServiceUrl), {params: apiParams, headers: apiCallHeader})
            .then(response => {
                if(process.env.VUE_APP_SHOW_CONSOLE === 'true') {
                    console.log("[API] SUCCESS! URL: " + process.env.VUE_APP_API_URL + apiServiceUrl + ", Action: " + apiCallAction + ", Stat: " + response.status + ", Data: " + JSON.stringify(apiParams));
                }
                if(response.data.token) {
                    setNewToken(response.data.token)
                }
                return response.data
            }).catch(error => {
                if(process.env.VUE_APP_SHOW_CONSOLE === 'true') {
                    console.log("[API] ERROR! URL: " + process.env.VUE_APP_API_URL + apiServiceUrl + ", Action: " + apiCallAction + ", Stat: " + error.response.status + ", Data: " + JSON.stringify(apiParams));
                }
                checkUnauthorized(error.response.status)
                return {error: {url: apiServiceUrl, status: error.response.status}, data:[]}
            })
    }

}
function setNewToken(tokenObject) {
    store.commit('GET_USER_TOKEN', tokenObject[0])
}
function checkUnauthorized(errorStatus) {
    if(errorStatus === 401) {
        router.push('/logout')
    }
}

export default {
    callApiService
}