<template>
  <div :id="componentId">
    <global-menu-bar :showUserMenu="showUserMenu"></global-menu-bar>
    <router-view></router-view>
    <Toast />
  </div>
</template>

<script>
import GlobalMenuBar from "@/components/global-components/globalMenuBar";

export default {
  name: 'App',
  components: {
    GlobalMenuBar
  },
  data() {
    return {
      componentId: 'mainComponent',
      showFolderDialogBoolean: false,
      thisUser: this.$store.getters.getUserData,
      showUserMenu: false,
      displayDialog: false
    }
  },
  methods: {
    tabActiveOnMouse() {
      this.tabActive = 0
    },
    tabInactiveOnMouse() {
      this.tabActive = 1
    },
    getUserMenu() {
      this.showUserMenu = this.$store.getters.getUserAuth
    }
  },
  computed: {
    theTitle() {
      return this.$route.meta.headline
    },
    checkNestedFolder() {
      return this.$route.params.folder;
    }
  },
  watch:{
    $route (to, from){
      if (to !== from) {
        this.getUserMenu()
      }
    }
  }

}
</script>

<style>
* {
  font-family: Arial, sans-serif;
}
body {
  padding: 0;
  margin: 0;
  background: rgb(233,233,233);
  background: linear-gradient(0deg,rgb(243 243 243),rgb(255 255 255));
  background-repeat: repeat;
  background-attachment: scroll;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
#app #mainComponent {
  margin-top: 125px;
}
/* MenuBar */
#app .p-menubar {
  background: #101010;
  border-radius: 0;
  border: none;
  border-bottom: 10px solid Indigo !important;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}
#app .p-grid {
  margin-left: 0;
  margin-right: 0;
}
#app .p-card {
  border-top: 4px solid #ced4da;
}
#app .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: white;
}
#app .p-menubar.p-menubar-mobile-active .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: black;
}
#app .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  box-shadow: none;
}
#app .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: none;
}
#app .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #8f00f5;
}
#app .header-grid {
  padding: 0.4rem 2rem;
  background-color: #6900b6 !important;
  color: white;
  position: fixed;
  width: 100%;
  top: 80px;
  z-index: 99;
}
#app .header-grid h1 {
  font-size: 1.4em;
  padding: 0;
  margin: 0;
  display: inline-block;
}
#app .header-button {
  float: right;
}

#app .body-grid {
  padding: 0 2rem;
}
#app .p-button, body .p-button {
  background: Indigo;
  border: none;
}
#app .p-button {
  padding: .3rem 1rem;
}
#app .p-button:hover, body .p-button:hover {
  background: #6900b6;
}
#app .p-fileupload .p-fileupload-buttonbar {
  background: #f1f1f1;
}
#app .p-inputtext {
  width: 100%;
}
#app .p-float-label {
  margin-bottom: 15px;
}
#app .p-menubar-start {
  margin-left: 0.5rem;
}
#app .p-toast .p-toast-message .p-toast-message-content {
  padding: 0.5rem;
}
#app .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 0rem;
}
#app .p-message .p-message-wrapper {
  padding: 0.5rem 0.8rem;
}
body .p-image-toolbar {
  z-index: 9999;
}
#body .p-image-action.p-link {
  z-index: 9999;
}
#app .download-accordion {
  margin-bottom: 20px;
  border-radius: 0;
}

/* Dialog */
body .p-dialog.shown-image {
  height: 100%;
  width: 100%;
  max-height: none;
}
body .p-dialog-header {
  display: block;
}

body .p-dialog.shown-image img, body .p-dialog.shown-image video {
  height: auto;
  width: auto;
  max-width: 98%;
  max-height: 100%;
}
body .p-dialog.shown-image .p-dialog-content {
  height: 95%;
  position: relative;
  padding: 0 25px;
  background: black;
  color: white;
}
.p-dialog .p-dialog-footer {
  overflow-y: auto;
}
#app .p-accordion .p-accordion-content {
  padding: 0;
}
#app .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}
#app #itemsUserMenus {
  z-index: 100;
}
.xl-folder-icon {
  text-align: center;
}
.xl-folder-icon img {
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}
.xl-folder-icon .xl-folder-name {
  text-align: center;
  font-size: 12px;
}
.breadcrumb-container a {
  font-size: 15px;
  text-decoration: none;
  color: grey;
  font-weight: bold;
}
.breadcrumb-container .pi {
  font-size: 12px;
  color: grey;
}
.p-fileupload-row > div:first-child {
  display: none;
}
.p-fileupload-row > div:nth-child(2) {
  width: 90%;
}
.p-fileupload-row > div:nth-child(3) {
  float: right;
  width: 150px;
}
.p-fileupload-row > div:nth-child(4) {
  float: right;
  width: 100px;
}
#app .p-menubar {
  min-height: 75px;
}
body .p-dialog.shown-image .p-dialog-header, body .p-dialog.shown-image .p-dialog-footer {
  background: #000;
}
#userOverview .p-accordion-header-link {
  background: #ebebeb;
}

#userOverview .p-accordion-header-link, #userOverview .p-fileupload .p-fileupload-buttonbar, #userOverview .p-fileupload-content, #userOverview .p-accordion .p-accordion-content {
  border-radius: 0 !important;
  border: none;
}

.p-accordion .p-accordion-tab {
  border-bottom: 1px solid #dee2e6;;
}

@media only screen and (max-width: 600px) {
  .button-text {
    display: none;
  }
  #app .body-grid {
    padding: 0 0.8rem;
  }
  #app .header-grid {
    padding: 0.4rem 0.8rem;
  }
  .button-icon {
    display: block;
  }
}
@media only screen and (min-width: 601px) {
  .button-icon {
    display: none !important;
  }
  .button-text {
    display: block;
  }
}
</style>
