import apiCallService from "@/_services/apiCallService";

const state = {
    allFiles: {},
    recentFiles: {},
    deletedFile: {},
    deleteMultipleFiles: null,
    silentStorage: null,
    singleTextFile: {}
}

const mutations = {
    GET_ALL_FILES(state, payload) {
        state.allFiles = payload;
    },
    CLEAR_ALL_FILES(state, payload) {
        state.allFiles = payload;
    },
    GET_RECENT_FILES(state, payload) {
        state.recentFiles = payload;
    },
    GET_DELETED_FILE(state, payload) {
        state.deletedFile = payload;
    },
    SET_DELETE_FILES(state, payload) {
        state.deleteMultipleFiles = payload;
    },
    SET(state, payload) {
        state.silentStorage = payload;
    },
    GET_SINGLE_TEXT_FILE(state, payload) {
        state.singleTextFile = payload;
    },
    CLEAR_SINGLE_TEXT_FILE(state, payload) {
        state.singleTextFile = payload;
    },
}

const actions = {
    getAllFiles({commit}, apiParams) {
        return apiCallService.callApiService('files/', 'get', apiParams )
            .then((response) => {
                commit('GET_ALL_FILES', response.data);
            })
    },
    checkVideoThumb({commit}, apiParams) {
        return apiCallService.callApiService('files/videothumb/', 'get', apiParams )
            .then((response) => {
                return response.data
            })
            .then(
                commit('SET', null)
            )

    },
    getSingleTextfile({commit}, apiParams) {
        return apiCallService.callApiService('files/textfile/', 'get', apiParams )
            .then((response) => {
                commit('GET_SINGLE_TEXT_FILE', response.data);
                return response.data;
            })
    },
    clearAllFiles({commit}) {
        commit('CLEAR_ALL_FILES', {});
    },
    multipleDelete({commit}, payload) {
        commit('SET_DELETE_FILES', payload);
    },
    getRecentFiles({commit}, apiParams) {
        return apiCallService.callApiService('files/recent/', 'get', apiParams )
            .then((response) => {
                commit('GET_RECENT_FILES', response.data);
            })
    },
    deleteSingleFile({commit}, apiParams) {
        return apiCallService.callApiService('files/delete/', 'post', apiParams )
            .then((response) => {
                commit('GET_DELETED_FILE', response);
                return response
            })
    }
}

const getters = {
    showAllFiles: state => state.allFiles,
    showRecentFiles: state => state.recentFiles,
    setDeleteMultiple: state => state.deleteMultipleFiles,
    showSingleTextfile: state => state.singleTextFile
}

const filesModule = {
    state,
    mutations,
    actions,
    getters
}

export default filesModule;
