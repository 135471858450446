<template>
  <Dialog :header="dialogHeader" :visible="displayDialog" :style="{width: '50vw'}" :closable="false">
    <slot></slot>
    <template #footer>
      <Button label="Nein" icon="pi pi-times" @click="clickNo" class="p-button-text"/>
      <Button label="Ja" icon="pi pi-check" @click="clickYes" autofocus />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "globalYesNoDialog",
  props: {
    displayDialog: Boolean,
    dialogHeader: String,
    elementId: Number
  },
  methods: {
    clickYes() {
      this.$emit('clickYes', this.elementId)
    },
    clickNo() {
      this.$emit('clickNo')
    }
  }
}
</script>

<style scoped>

</style>