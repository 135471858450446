import apiCallService from "@/_services/apiCallService";

const state = {
    allCurentFolders: {},
    singleCurentFolder: {},
    folderStructure: {},
    createdFolder: {}
}

const mutations = {
    GET_ALL_CURENT_FOLDERS(state, payload) {
        state.allCurentFolders = payload;
    },
    CLEAR_ALL_CURENT_FOLDERS(state, payload) {
        state.allCurentFolders = payload;
    },
    SET_CREATED_FOLDER(state, payload) {
        state.createdFolder = payload;
    },
    GET_SINGLE_CURENT_FOLDER(state, payload) {
        state.singleCurentFolder = payload;
    },
    CLEAR_SINGLE_CURENT_FOLDER(state, payload) {
        state.singleCurentFolder = payload;
    },
    SET_FOLDER_STRUCTURE(state, payload) {
        state.folderStructure = payload;
    }
}

const actions = {
    getAllCurentFolders({commit}, apiParams) {
        apiParams['action'] = 'all';
        return apiCallService.callApiService('folders/', 'get', apiParams )
            .then((response) => {
                commit('GET_ALL_CURENT_FOLDERS', response.data);
            })
    },
    clearAllCurentFolder({commit}) {
        commit('CLEAR_SINGLE_CURENT_FOLDER', {});
    },
    setFolderStructure({commit}, apiParams) {
        return apiCallService.callApiService('folders/structure/', 'get', apiParams )
            .then((response) => {
                commit('SET_FOLDER_STRUCTURE', response.data);
            })
    },
    async getSingleCurentFolder({commit}, apiParams) {
        apiParams['action'] = 'single';
        await apiCallService.callApiService('folders/', 'get', apiParams )
            .then((response) => {
                commit('GET_SINGLE_CURENT_FOLDER', response.data);
            })
            .then((response) => {
                return response
        })
    },
    clearSingleCurentFolder({commit}) {
        commit('CLEAR_SINGLE_CURENT_FOLDER', {});
    },
    createNewFolder({commit}, apiParams) {
        return apiCallService.callApiService('folders/', 'post', apiParams )
            .then((response) => {
                commit('SET_CREATED_FOLDER', response.data);
            })
    }
}

const getters = {
    showCurentFolders: state => state.allCurentFolders,
    showCurentFold: state => state.singleCurentFolder,
    getFolderStructure: state => state.folderStructure
}

const folderModule = {
    state,
    mutations,
    actions,
    getters
}

export default folderModule;
