import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";
import globalLogin from "../components/global-components/globalLogin";
import userDashboard from "@/components/user-components/userDashboard";
import userProfile from "@/components/user-components/userProfile";
import userSettings from "@/components/user-components/userSettings";
import globalLogout from "@/components/global-components/globalLogout";
import userOverview from "@/components/user-components/userOverview";
import publicDownload from "@/components/global-components/publicDownload";
import publicNotFound from "@/components/global-components/publicNotFound";

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'Login',
        component: globalLogin,
        meta: {
            needAuth: false,
            metaTitle: 'Login',
            headline: 'Login'
        }
    },
    {
        path: '/dateien/:folder*',
        name: 'Dateien',
        component: userOverview,
        meta: {
            needAuth: true,
            metaTitle: 'Dateien',
            headline: 'Meine Dateien'
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: userDashboard,
        meta: {
            needAuth: true,
            metaTitle: 'Dashboard',
            headline: 'Mein Dashboard'
        }
    },
    {
        path: '/profil',
        name: 'Profil',
        component: userProfile,
        meta: {
            needAuth: true,
            metaTitle: 'Profil',
            headline: 'Mein Profil'
        }
    },
    {
        path: '/einstellungen',
        name: 'Einstellungen',
        component: userSettings,
        meta: {
            needAuth: true,
            metaTitle: 'Einstellungen',
            headline: 'Einstellungen'
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        component: globalLogout,
        meta: {
            needAuth: true,
            metaTitle: 'Logout',
            headline: 'Logout'
        }
    },
    {
        path: '/download/:downloadId',
        name: 'Download',
        component: publicDownload,
        meta: {
            needAuth: false,
            metaTitle: 'Download',
            headline: 'Download'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: publicNotFound,
        meta: {
            needAuth: false,
            metaTitle: '404 Nicht gefunden',
            headline: '404 Nicht gefunden'
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach((to, from, next) => {
    if(to.meta.needAuth === true && store.getters.getUserAuth === false && to.name !== 'Login') {
        document.title = process.env.VUE_APP_TITLE_PRAEFIX + 'Login';
        router.push('/login')
    }
    else if (store.getters.getUserAuth === true && to.name === 'Login') {
        document.title = process.env.VUE_APP_TITLE_PRAEFIX + 'Dashboard';
        router.push('/dashboard')
    }
    else {
        document.title = process.env.VUE_APP_TITLE_PRAEFIX + to.meta.metaTitle;
        next();
    }
});

export default router
