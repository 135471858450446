<template>
  <Dialog class="shown-image" :visible="showImageModal" :closable="false" :modal="true">
    <template #header>
      <div style="float: left" class="dialog-image-header"><b>{{file.name}}.{{file.ext}}</b></div>
      <Button style="float: right; margin-left: 10px;" type="button" @click="closeImage">
        <i class="pi pi-times"></i>
      </Button>
    </template>
    <div class="show-image-container" v-html="thisContent">
    </div>
    <template #footer></template>
  </Dialog>
</template>

<script>
export default {
  name: "showSingleTextfile",
  props: {
    showImageModal: Boolean,
    file: Object
  },
  data() {
    return {
      dataPath: process.env.VUE_APP_FILE_URL,
      thisContent: null,
      thisUser: this.$store.getters.getUserData
    }
  },
  methods: {
    closeImage() {
      this.$emit('closeTextfile');
    },
    showTextfile() {
      this.$store.dispatch('getSingleTextfile', {file: this.file.name + '.' + this.file.ext, user: this.thisUser.id})
          .then((response) => {
            this.thisContent = response[0].replace(/(?:\r\n|\r|\n)/g, '<br>')
          })
    }
  },
  created() {
    this.showTextfile()
  }
}
</script>

<style scoped>
.show-image-container {
  height: 100%;
  text-align: left;
  overflow: auto;
  padding: 20px;
}
.gallery-panel {
  height: 100%;
  position: absolute;
  width: 10%;
}
.gallery-panel.left {
  left: 0;
}
.gallery-panel.left:hover {
  left: 0;
  background: rgb(199,199,199);
  background: linear-gradient(90deg, rgb(88, 88, 88) 0%, rgba(199,199,199,0) 100%);
}
.gallery-panel.right {
  right: 0;
}
.gallery-panel.right:hover {
  right: 0;
  background: rgb(199,199,199);
  background: linear-gradient(90deg, rgba(199,199,199,0) 0%, rgb(111, 111, 111) 100%);
}
.gallery-panel.right:hover .gallery-icon, .gallery-panel.left:hover .gallery-icon {
  color: #6900b6;
}
.gallery-icon {
  position: absolute;
  font-size: 2rem;
  color: black;
}
.gallery-icon-left {
  position: absolute;
  left: 25px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 32px;
  height: 32px;
}
.gallery-icon-right {
  position: absolute;
  right: 25px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 32px;
  height: 32px;
}
.dialog-image-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #FFF;
}
</style>