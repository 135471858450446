import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import authModule from "@/store/global-modules/authModule";
import folderModule from "@/store/user-modules/folderModule";
import fileModule from "@/store/user-modules/fileModule";
import storageModule from "@/store/global-modules/storageModule";
import downloadModule from "@/store/global-modules/downloadModule";

const store = createStore({
    plugins: [createPersistedState()],
    modules: {
        authModule,
        folderModule,
        fileModule,
        storageModule,
        downloadModule
    }
});

export default store;
