<template>
  <div :id="componentId">
    <div class="p-grid header-grid">
      <div class="p-col-12 ">
        <h1>{{theTitle}}</h1>
      </div>
    </div>
    <div class="p-grid body-grid">
      <div class="p-col-12">
        <h3>Mein Speicher</h3>
        <div class="storage-wrapper">
          <div class="storage-bar" :style="'background-size: ' + percentOfStorage + '% auto;'">{{usedStorage}} / {{myStorage}}MB</div>
        </div>
      </div>
      <div class="p-col-12">
        <h3>Letzte Uploads</h3>
      </div>
    </div>
    <div class="p-grid body-grid">
      <div v-for="file in recentFiles" :key="file.id" class="p-col-4 p-md-2 p-lg-1">
        <show-single-file :file="file"></show-single-file>
      </div>
    </div>
  </div>
</template>

<script>
import showSingleFile from "@/components/global-components/micro-components/showSingleFile";

export default {
  name: "userDashboard",
  components: {
    showSingleFile
  },
  data() {
    return {
      componentId: 'userDashboard',
      thisUser: this.$store.getters.getUserData,
      myStorage: 50000
    }
  },
  created() {
    this.$store.dispatch('getUserUsedStorage', {user: this.thisUser.id});
    this.$store.dispatch('getRecentFiles', {user: this.thisUser.id});
  },
  computed: {
    theTitle() {
      return this.$route.meta.headline;
    },
    usedStorage() {
      if(this.$store.getters.getUsedStorage.data[1]) {
        return ((Math.round(parseInt(this.$store.getters.getUsedStorage.data[1].storage) * 100) / 100) / 1000000).toFixed(2);
      } else {
        return 0
      }
    },
    percentOfStorage() {
      return Math.floor((this.usedStorage / this.myStorage) * 100);
    },
    recentFiles() {
      return this.$store.getters.showRecentFiles;
    }
  }
}
</script>

<style scoped>
.storage-bar {
  background-color: rgb(33, 150, 243);
  background: linear-gradient(
      0deg, rgb(255 0 0) 0%, rgb(255 0 0) 100%);
  background-repeat: repeat;
  background-attachment: scroll;
  background-attachment: fixed;
  background-repeat: no-repeat;
  text-align: center;
  padding: 3px 0;
  font-weight: bold;
  border-radius: 3px;
}
.storage-wrapper {
  background: #e9e9e9;
  border-radius: 3px;
}
</style>
