<template>
  <Dialog class="shown-image" :visible="showImageModal" :closable="false" :modal="true">
    <template #header>
      <div style="float: left" class="dialog-image-header"><b>{{file.name}}.{{file.ext}}</b></div>
      <Button style="float: right; margin-left: 10px;" type="button" @click="closeImage">
        <i class="pi pi-times"></i>
      </Button>
      <Button v-if="canStartDiashow" style="float: right;" type="button" @click="startDiashow('next')">
        <i class="pi pi-caret-right"></i>
      </Button>
      <Button v-else style="float: right;" type="button" @click="stopDiashow">
        <i class="pi pi-ban"></i>
      </Button>
    </template>
    <!--
    <canvas style="height: 0; width: 0;">
    </canvas>
    <img style="height: 0; width: 0;" crossOrigin = "Anonymous" id="screenshot"/>
    -->
    <div @click="nextImage('previous')" class="gallery-panel left"><i class="pi pi-chevron-left gallery-icon gallery-icon-left"></i></div>
    <div @click="nextImage('next')" class="gallery-panel right"><i class="pi pi-chevron-right gallery-icon gallery-icon-right"></i></div>
    <div v-if="file.ext === 'jpg' || file.ext === 'jpeg' || file.ext === 'png'" class="show-image-container">
      <img :src="dataPath + file.name + '_medium.jpg'" :alt="file.name">
    </div>
    <div v-else class="show-image-container">
      <video
          playsinline
          controls
          class="stream"
          :loop="true"
          :key="dataPath + file.name + '.' + file.ext"
          :src="dataPath + file.name + '.' + file.ext"
      />
    </div>
    <template #footer></template>
  </Dialog>
</template>

<script>
export default {
  name: "showSingleImage",
  props: {
    showImageModal: Boolean,
    file: Object
  },
  data() {
    return {
      dataPath: process.env.VUE_APP_FILE_URL,
      canStartDiashow: true
    }
  },
  methods: {
    closeImage() {
      this.$emit('closeImage');
    },
    getVideoThumb() {
      let video = document.querySelector('video');
      let canvas = document.querySelector('canvas');
      // Get a handle on the 2d context of the canvas element
      let context = canvas.getContext('2d');
      // Define some vars required later
      let w, h, ratio;



      ratio = video.videoWidth / video.videoHeight;
      // Define the required width as 100 pixels smaller than the actual video's width
      w = video.videoWidth - 100;
      // Calculate the height based on the video's width and the ratio
      h = parseInt(w / ratio, 10);

      canvas.width = w;
      canvas.height = h;

      // draw the image
      context.fillRect(0, 0, w, h);
      context.drawImage(video, 0, 0, w, h);

      canvas.crossOrigin = "Anonymous";
      console.log(context)

      // set it as img
      //  let dataURL = canvas.toDataURL();

      canvas.toBlob(function(blob) {
        let newImg = document.createElement('img'),
            url = URL.createObjectURL(blob);

        newImg.onload = function() {
          // no longer need to read the blob so it's revoked
          URL.revokeObjectURL(url);
        };

        newImg.src = url;
        document.body.appendChild(newImg);
      });
      // document.getElementById('screenshot').src = dataURL;
    },
    nextImage(action) {
      this.$emit('nextImage', {action: action, image: this.file.id})
    },
    startDiashow(action) {
      this.canStartDiashow = false
      this.$emit('startDiashow', {action: action, image: this.file.id})
    },
    stopDiashow() {
      this.canStartDiashow = true
      this.$emit('stopDiashow')
    },
  }
}
</script>

<style scoped>
.show-image-container {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-panel {
  height: 100%;
  position: absolute;
  width: 10%;
}
.gallery-panel.left {
  left: 0;
}
.gallery-panel.left:hover {
  left: 0;
  background: rgb(36,36,36);
  background: linear-gradient(90deg, rgb(36, 36, 36) 0%, rgba(199,199,199,0) 100%);
}
.gallery-panel.right {
  right: 0;
}
.gallery-panel.right:hover {
  right: 0;
  background: rgb(36,36,36);
  background: linear-gradient(90deg, rgba(199,199,199,0) 0%, rgb(36, 36, 36) 100%);
}
.gallery-panel.right:hover .gallery-icon, .gallery-panel.left:hover .gallery-icon {
  color: #6900b6;
}
.gallery-icon {
  position: absolute;
  font-size: 2rem;
  color: black;
}
.gallery-icon-left {
  position: absolute;
  left: 25px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 32px;
  height: 32px;
}
.gallery-icon-right {
  position: absolute;
  right: 25px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 32px;
  height: 32px;
}
.dialog-image-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #FFF;
}
</style>