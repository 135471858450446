<template>
  <Menubar :model="menuItems">
    <template #start>
      <router-link class="home-link" to="/">DATAPARK</router-link>
    </template>
    <template #end>
      <Avatar v-if="showUserMenu" icon="pi pi-user" class="p-mr-2" size="large" @click="toggle" style="background-color:#000000; color: #ffffff" />
      <MegaMenu v-if="showUserMenu" @click="toggle" style="display:none;" id="itemsUserMenus" :model="itemsUserMenu" orientation="vertical" />
    </template>
  </Menubar>
</template>

<script>
export default {
  name: "globalMenuBar",
  props: {
    showUserMenu: Boolean
  },
  data() {
    return {
      menuItems: [
        {
          label:'Übersicht',
          to: "/dashboard",
          visible: () => this.showUserMenu
        },
        {
          label:'Dateien',
          to: "/dateien",
          visible: () => this.showUserMenu
        }
      ],
      itemsUserMenu: [
        {
          label:'Profil',
          to: "/profil",
          icon:'pi pi-fw pi-user-minus',

        },
        {
          label:'Einstellungen',
          to: "/einstellungen",
          icon:'pi pi-fw pi-user-plus',
        },
        {
          label:'Logout',
          to: "/logout",
          icon:'pi pi-fw pi-user-plus',
        }
      ]
    }
  },
  methods: {
    toggle() {
      if(this.$route.name !== 'Login') {
        let x = document.getElementById("itemsUserMenus");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      }
    }
  }
}
</script>

<style scoped>
#itemsUserMenus {
  position: absolute;
  right: 0.5rem;
}
.home-link {
  text-decoration: none;
  font-weight: bold;
  color: white;
  margin-right: 40px;
}
</style>
